import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import { IconHeart } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix, intl, currentUser, onFavourite, isFavourite } = props;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <section className={css.productGallery} data-testid="carousel">
      {
        !!currentUser ? 
        <div onClick={onFavourite} title={intl.formatMessage({ id: 'ListingPage.addToFavourites' })} className={css.favouritesWrapper}>
          <IconHeart className={css.favourite} filled={isFavourite} />
        </div> : null
      }
      <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
      />
    </section>
  );
};

export default SectionGallery;
